// use to initialize state
import { format } from 'date-fns'
import { loadData } from 'services/user'
import { getBuildings } from 'services/buildings'
import { getConstants } from 'services/common'
import { getCategories } from 'services/categories'

import { setUser } from 'reducers/user/actions'
import { addGoods } from 'reducers/cart/actions'
import { setBuildings } from 'reducers/buildings/actions'
import { setUsStates, setProductConstants, setOrderConstants } from 'reducers/common/actions'
import { setCategories } from 'reducers/category/actions'
import { store, dispatch } from '../store'

export const loadDataInit = async () => {
  loadConstants()
  const [user, buildings] = await Promise.all([
    loadUser(),
    loadBuildings(),
    loadCategories(),
  ])
  if (user && buildings) {
    userAndBuildingLoaded({ user, buildings })
  } else {
    loadCartLocalStorage()
  }
}

const loadUser = async () => {
  // load user
  let user = localStorage.getItem('user')
  if (user) {
    try {
      user = JSON.parse(user)
    } catch (err) {
      localStorage.removeItem('user')
      return
    }
    const res = (await loadData(user._id))
    if (res.result !== 'success') return
    const userData = res.data
    dispatch(setUser(userData))
    return userData
  }
}

const loadCartLocalStorage = () => {
  let cart = localStorage.getItem('cart')
  if (cart) {
    cart = JSON.parse(cart)
    dispatch(addGoods(cart.goods))
  }
}

const loadCart = (cart = []) => {
  const cartObj = cart.reduce((arr, cur) => {
    arr[cur._id] = cur
    return arr
  }, {})
  dispatch(addGoods(cartObj))
}

const loadBuildings = async () => {
  const buildingsRes = (await getBuildings()).data
  const buildings = buildingsRes.map(building => ({
    ...building,
    deliveryDays: building.deliveryDays.map(day => ({
      value: day,
      format: format(new Date(day), 'MMM dd, yyyy, h:mm a')
    }))
  }))
  dispatch(setBuildings(buildings))
  return buildings
}

const loadConstants = async () => {
  const constantsRes = (await getConstants()).data
  dispatch(setUsStates(constantsRes.usStates))
  dispatch(setProductConstants(constantsRes.product))
  dispatch(setOrderConstants(constantsRes.order))
}

export const userAndBuildingLoaded = ({
  user = store.getState().user,
  buildings = store.getState().buildings.buildings,
}) => {
  const buildingFound = user.building ? buildings.find(b => b._id === user.building) : undefined
  dispatch(setUser({
    ...user,
    building: buildingFound
  }))
  loadCart(user.cart)
}

const loadCategories = async () => {
  const categoriesRes = (await getCategories()).data
  const categoriesObj = {}
  categoriesRes.categories.forEach(c => categoriesObj[c._id] = { ...c, subcategories: [] })
  categoriesRes.subcategories.forEach(s => {
    if (categoriesObj[s.category]) {
      categoriesObj[s.category].subcategories.push(s)
    }
  })
  dispatch(setCategories(Object.values(categoriesObj)))
}