import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography';

import HorizontalProductList from './HorizontalProducts/HorizontalProductList';

import {
  togglePickupCalendar as togglePickupCalendarState,
} from 'reducers/modals/actions'
import { setCategories } from 'reducers/category/actions'
import { addGood, changeQuantity } from 'reducers/cart/actions'

import { updateCart } from 'services/cart'

const RecommendedProducts = ({
  products = [],
  // state
  cart,
  onSelectProduct,
  onAdd,
  onRemove,
  addToCart,
  user,
}) => {
  const [productsWithCart, setProductsWithCart] = useState(products)

  const requestUpdateCart = useCallback(_.debounce((goods) => {
    if (user && user._id) {
      const payload = Object.values(goods).map(({ _id, quantity = 1 }) => ({ id: _id, quantity }))
      updateCart(payload)
    }
  }, 1000), [])

  const updateProductsWithCart = useCallback(() => {
    const productsWithCart = products.map(product => {
      if (cart.goods[product._id]) {
        product.quantity = cart.goods[product._id].quantity
      } else {
        delete product.quantity
      }
      return { ...product }
    })
    setProductsWithCart(productsWithCart)
  }, [cart.goods, products])

  useEffect(() => {
    updateProductsWithCart()
  }, [updateProductsWithCart])

  useEffect(() => {
    requestUpdateCart(cart.goods)
  }, [cart, requestUpdateCart])

  // recommended list does not contain quantity info
  const openProductFromBoughtList = product => {
    const productFromCart = cart.goods[product._id]
    if (productFromCart) product.quantity = productFromCart.quantity
    onSelectProduct(product)
  }
  return (
    <>
      {products && products.length > 0 && (
        <div className='recommended-products'>
          <Typography variant="h5" gutterBottom className='recommended-products-title'>
            Recommended
          </Typography>
          <HorizontalProductList
            products={productsWithCart}
            onProductClick={openProductFromBoughtList}
            addToCart={addToCart}
            onAdd={onAdd}
            onRemove={onRemove}
            className='recommended-products-list'
          />
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ cart, user }) => ({
  cart,
  user,
})
const mapDispatchToProps = dispatch => ({
  addItem: (item) => dispatch(addGood({ item })),
  changeItemQuantity: (id, quantity) => dispatch(changeQuantity(id, quantity)),
  togglePickupCalendar: open => dispatch(togglePickupCalendarState({ open })),
  setCategoriesState: categories => dispatch(setCategories(categories)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedProducts)
