import { updatePermission } from 'reducers/permissions/actions'
import { PERMISSIONS } from './constants'

const PERMISSION_TYPE = PERMISSIONS.location.type

const getActionPayload = (result, data) => ({
  type: PERMISSION_TYPE,
  result,
  data,
})

const getLocation = () => {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(pos => {
      resolve(pos.coords)
    }, err => {
      console.log('getLocation failed', err)
      resolve()
    })
  });
}
export const requestLocationPermission1 = async (dispatch) => {
  let result
  try {
    result = await navigator.permissions.query({ name: 'geolocation' })
  } catch (err) {
    console.error('Error requesting location permission')
  }
  if (result.state == 'granted' || result.state == 'prompt') {
    const location = await getLocation()
    const payload = getActionPayload(!!location, location)
    dispatch(updatePermission(payload))
  } else if (result.state == 'denied') {
    dispatch(updatePermission(getActionPayload(false)))
  }
}

export const requestLocationPermission = async (dispatch) => {
  let result
  try {
    result = await getLocation()
  } catch (err) {
    console.error('Error requesting location permission')
  }
  const payload = getActionPayload(!!result, result)
  dispatch(updatePermission(payload))
}