import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import {
  Collapse,
  Nav,
  NavbarToggler,
  NavItem,
} from 'reactstrap'

import LoginModal from 'components/Modals/LoginModal/LoginModal'
import SignUpModal from 'components/Modals/SignUpModal/SignUpModal'
import ForgotPasswordModal from 'components/Modals/ForgotPasswordModal/ForgotPasswordModal'
import HeaderCart from '../components/HeaderCart'
import CartModal from 'components/Modals/Cart'
import HelpModal from 'components/Modals/HelpModal'
import HowItWorkModal from 'components/Modals/HowItWork'
import HeaderMenu from '../components/HeaderMenu'

import {
  toggleLogin as toggleLoginState,
  toggleSignup as toggleSignupState,
  toggleForgotPassword as toggleForgotPasswordState,
  toggleCart as toggleCartState,
  toggleHelp as toggleHelpState,
  toggleHowItWork as toggleHowItWorkState,
} from 'reducers/modals/actions'

const Header = ({
  openLogin,
  openSignUp,
  openForgotPwd,
  toggleLogin,
  toggleSignup,
  toggleForgotPassword,
  toggle: toggleHeader,
  isCollapseOpen,
  products,
  toggleCart,
  openCart,
  toggleHelp,
  toggleHowItWork,
  openHelp,
  openHowItWork,
}) => {
  /**
   * For anonymous customer: 
   * - Allow cart managing in unauthenticated header
   */
  const [anchorEl, setAnchorEl] = useState(null)
  const [itemCountInCart, setItemCountInCart] = useState(0)
  const updateCartCount = (products) => {
    setItemCountInCart(Object.keys(products).length)
  }
  useEffect(() => {
    updateCartCount(products)
  }, [products])
  const toggleCartModal = open => {
    toggleCart(open)
    toggleHeader()
  }

  const handleOpenLogin = () => {
    toggleLogin(true)
    toggleHeader()
  };
  const handleCloseLogin = () => {
    toggleLogin(false)
  };

  const handleOpenSignUp = () => {
    toggleSignup(true);
    toggleHeader()
  };
  const handleCloseSignUp = (item) => {
    toggleSignup(false);
  };
  const handleCloseForgotPassword = () => {
    toggleForgotPassword(false)
  }

  const toSignup = () => {
    toggleLogin(false)
    toggleForgotPassword(false)
    toggleSignup(true)
  }
  const toLogin = () => {
    toggleSignup(false)
    toggleForgotPassword(false)
    toggleLogin(true)
  }
  const toForgotPwd = () => {
    toggleSignup(false)
    toggleLogin(false)
    toggleForgotPassword(true)
  }

  const toggleHelpModal = open => {
    toggleHelp(open)
    toggleHeader()
  }

  const toggleHowItWorkModal = open => {
    toggleHowItWork(open)
    toggleHeader()
  }

  const UtilityItemsNav = () => {
    // only show on mobile
    return <>
      <NavItem className="mobile" onClick={toggleHowItWorkModal}>
        <div className="unauth-btn">
          How It Works
        </div>
      </NavItem>
      <NavItem className="mobile" onClick={toggleHelpModal}>
        <div className="unauth-btn">
          Help
        </div>
      </NavItem>
    </>
  }

  const UtilityMenu = () => {
    return (
      <div className="non-mobile">
        <NavbarToggler onClick={event => setAnchorEl(event.currentTarget)} />
        <HeaderMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          menuItems={[
            { onClick: toggleHowItWorkModal, title: 'How It Works' },
            { onClick: toggleHelpModal, title: 'Help' },
          ]}
        />
      </div>
    )
  }

  return (
    <>
      <NavbarToggler onClick={toggleHeader} />
      <Collapse isOpen={isCollapseOpen} navbar className='justify-content-end px-4 header-right'>
        <Nav navbar className="unauthed-nav">
          <NavItem className="notification-item authed-item" onClick={() => toggleCartModal(true)}>
            <HeaderCart itemCountInCart={itemCountInCart} />
          </NavItem>
          <NavItem className="notification-item" onClick={handleOpenLogin}>
            <div className="nav-signup">
              Login
            </div>
          </NavItem>
          <NavItem className="notification-item" onClick={handleOpenSignUp}>
            <div className="nav-login">
              Sign Up
            </div>
          </NavItem>
          {UtilityItemsNav()}
          <NavItem className="notification-item">
            {UtilityMenu()}
          </NavItem>
          <LoginModal open={openLogin} handleClose={handleCloseLogin} toSignup={toSignup} toForgotPassword={toForgotPwd} />
          <SignUpModal open={openSignUp} handleClose={handleCloseSignUp} toLogin={toLogin} />
          <ForgotPasswordModal open={openForgotPwd} handleClose={handleCloseForgotPassword} toLogin={toLogin} />
          <CartModal open={openCart} handleClose={() => toggleCartModal(false)} />
          <HelpModal open={openHelp} handleClose={() => toggleHelpModal(false)} />
          <HowItWorkModal open={openHowItWork} handleClose={() => toggleHowItWorkModal(false)} />
        </Nav>
      </Collapse>
    </>
  )
}

const mapStateToProps = ({ modals, cart }) => ({
  openLogin: modals.login.open,
  openSignUp: modals.signup.open,
  openForgotPwd: modals.forgotPassword.open,
  products: cart.goods,
  openCart: modals.cart.open,
  openHelp: modals.help.open,
  openHowItWork: modals.howItWork.open,
})

const mapDispatchToProps = dispatch => ({
  toggleLogin: open => dispatch(toggleLoginState({ open })),
  toggleSignup: open => dispatch(toggleSignupState({ open })),
  toggleForgotPassword: open => dispatch(toggleForgotPasswordState({ open })),
  toggleCart: open => dispatch(toggleCartState({ open })),
  toggleHelp: open => dispatch(toggleHelpState({ open })),
  toggleHowItWork: open => dispatch(toggleHowItWorkState({ open })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
