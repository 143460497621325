import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import ProgressButton from 'components/common/button/ProgressButton'
import InputStyle2 from 'components/common/inputs/style2'
import Autocomplete from 'components/common/autocomplete'
import Checkbox from 'components/common/checkbox'
import BaseModal from 'components/common/modal'

import TermsAndConditions from 'components/Main/Policies/TermsAndConditions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-regular-svg-icons';

import { userAndBuildingLoaded } from 'utils/state_init' 

import "./_styles.scss";

// redux
import { toggleHowItWork } from 'reducers/modals/actions'

// network
import { signup } from 'services/user'

// utils
import { reformatPhone } from 'utils/helper'

const SignUpModal = ({
  open,
  handleClose,
  toLogin,
  buildings,
  dispatch,
}) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [building, setBuilding] = useState({})
  const [buildingUnitNo, setBuildingUnitNo] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPwd, setShowPwd] = useState(false)
  const [showConfirmPwd, setShowConfirmPwd] = useState(false)
  const [checked, setChecked] = useState(false);
  const [openTADModal, setOpenTADModal] = useState(false)

  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (buildings.length > 0) {
      setBuilding(buildings[0])
    }
  }, [buildings])

  const onFirstNameChange = e => {
    setFirstName(e.target.value)
  }
  const onLastNameChange = e => {
    setLastName(e.target.value)
  }
  const onEmailChange = e => {
    setEmail(e.target.value)
  }
  const onPhoneChange = e => {
    setPhone(reformatPhone(e.target.value))
  }
  const onBuildingUnitNoChange = e => {
    setBuildingUnitNo(e.target.value)
  }
  const onPasswordChange = e => {
    setPassword(e.target.value)
  }
  const onConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value)
  }
  const onBuildingChange = building => {
    if (building) setBuilding(building)
  }

  const onSignup = async (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      return setError('Passwords do not match')
    } else {
      setError('')
    }
    setSubmitting(true)
    const res = await signup({
      firstName,
      lastName,
      email,
      phone: phone.replace(/\D/g, ''),
      password,
      building: building._id,
      buildingUnitNo,
    })
    if (res.result !== 'success') {
      setSubmitting(false)
      return setError(res.err)
    }
    setSubmitting(false)
    localStorage.setItem('user', JSON.stringify(res.data))
    // dispatch
    userAndBuildingLoaded({
      user: res.data,
    })
    dispatch(toggleHowItWork({ open: true }))
    handleClose()
  }

  const onToLogin = e => {
    e.preventDefault()
    toLogin()
  }

  const setTermsAndConditionsChecked = checked => {
    setChecked(checked)
    checked && setOpenTADModal(true)
  }

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      styles={{
        maxHeight: '70%',
      }}
      className='signup-modal'
    >
      <div role="dialog" aria-labelledby="exampleModalCenterTitle" className='login-container'>
        <div className="login_header text-center">
          <h4 className="mb-3">Create an account</h4>
        </div>
        <div className="modal-body">
          <div className="form mt-3">
            <form onSubmit={onSignup}>
              <div className="input-group form-group position-relative" tabIndex="0">
                <InputStyle2 type="text" onChange={onFirstNameChange} placeholder="First name *" />
              </div>
              <div className="input-group form-group position-relative" tabIndex="1">
                <InputStyle2 type="text" onChange={onLastNameChange} placeholder="Last name *" />
              </div>
              <div className="input-group form-group position-relative" tabIndex="2">
                <InputStyle2 onChange={onEmailChange} type="email" placeholder="Email *" />
              </div>
              <div className="input-group form-group position-relative" tabIndex="3">
                <InputStyle2
                  onChange={onPhoneChange}
                  type="text"
                  placeholder="Phone Number"
                  value={phone}
                />
              </div>
              <div className="input-group form-group position-relative" tabIndex="4">
                <Autocomplete
                  placeholder="Building *"
                  options={buildings}
                  labelKey="name"
                  onChange={onBuildingChange}
                  className="input-style input-style-2"
                  value={building}
                />
              </div>
              <div className="input-group form-group position-relative" tabIndex="5">
                <InputStyle2 onChange={onBuildingUnitNoChange} type="text" placeholder="Building Unit Number" />
              </div>
              <div className="input-group form-group position-relative" tabIndex="6">
                <InputStyle2 onChange={onPasswordChange} type={showPwd ? 'text' : 'password'} placeholder="Password *" />
                <div className="input-group-addon position-absolute show-hide-pwd">
                  {showPwd === false && <a href="#" onClick={() => setShowPwd(true)} className="black_color"><FontAwesomeIcon icon={faEyeSlash} color='#808080' /></a>}
                  {showPwd === true && <a href="#" onClick={() => setShowPwd(false)} className="black_color"><FontAwesomeIcon icon={faEye} color='#808080' /></a>}
                </div>
              </div>
              <div className="input-group form-group position-relative" tabIndex="7">
                <InputStyle2 onChange={onConfirmPasswordChange} type={showConfirmPwd ? 'text' : 'password'} placeholder="Confirm Password *" />
                <div className="input-group-addon position-absolute show-hide-pwd">
                  {showConfirmPwd === false && <div role="button" onClick={() => setShowConfirmPwd(true)} className="black_color"><FontAwesomeIcon icon={faEyeSlash} color='#808080' /></div>}
                  {showConfirmPwd === true && <div role="button" onClick={() => setShowConfirmPwd(false)} className="black_color"><FontAwesomeIcon icon={faEye} color='#808080' /></div>}
                </div>
              </div>
              <div className="input-group form-group position-relative" tabIndex="8">
                <Checkbox
                  label="I agree with terms and conditions"
                  name="registrationCheckbox"
                  checked={checked}
                  onClick={() => setTermsAndConditionsChecked(!checked)}
                />
              </div>
              {error && (
                <p style={{ color: 'red', textAlign: 'center' }} className="mb-0">{error}</p>
              )}
              <div className="form-group submit_button mt-4">
                <ProgressButton
                  classes='success'
                  style={{
                    height: '56px',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                  type="submit"
                  submitting={submitting}
                  disabled={!checked}
                >
                  Sign up
                </ProgressButton>
              </div>
            </form>
            <div className="text-center mt-4">
              <div className="a-tag signup_btn black_color" onClick={onToLogin}>Are you already a member?</div>
            </div>
          </div>

        </div>
      </div>
      <BaseModal
        open={openTADModal}
        onClose={() => setOpenTADModal(false)}
        className='tac-modal'
      >
        <TermsAndConditions />
      </BaseModal>
    </BaseModal>
  );
};

const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildings,
})

export default connect(mapStateToProps)(SignUpModal);
