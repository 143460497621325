import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import GoodsList from './components/goods-list';
import GoodsCard from './components/goods-card';
import CartEmpty from './components/cart-empty'
import { getAllTypes } from './Network';

import { getFullPrice } from 'utils/helper'

import './styles.scss';
import LocationIcon from './icons/location.svg'
import PackageIcon from './icons/package.svg'

import {
  togglePickupCalendar as togglePickupCalendarState,
} from 'reducers/modals/actions'

const Cart = ({
  products,
  categories,
  onCheckoutClick,
}) => {
  const history = useHistory()

  const [fullPrice, setFullPrice] = useState(0)
  const [goods, setGoods] = useState([])

  useEffect(() => {
    const productList = Object.values(products).map(product => {
      const { subcategories = [], ...category } = categories.find(c => c._id === product.category)
      return {
        ...product,
        category: category
      }
    })
    setGoods(productList)
    setFullPrice(getFullPrice(productList))
  }, [categories, products])

  const onCheckout = () => {
    history.push(`/checkout`)
    onCheckoutClick()
  }
  return (
    <article className="goods-cart">
      <h2 className="goods-cart__title">
        My cart
      </h2>
      <p className="goods-cart__desc">
        Your cart items, choose delivery time at checkout.
        <br />
        <Tooltip title="Prices are higher than in store">
          <span>PRICING</span>
        </Tooltip>
      </p>
      {goods.length === 0 ? (
        <CartEmpty />
      ) : (
        getAllTypes(goods).map((goodType, i) => {
          const goodsByType = goods.filter(({ category }) => category.label === goodType)
          return (
            <GoodsList
              key={`${goodType}-${i}`}
              title={goodType}
            >
              {goodsByType.map((product) => (
                <GoodsCard
                  key={product._id}
                  id={product._id}
                  {...product} />
              ))}
            </GoodsList>
          )
        })
      )}

      {goods.length > 0 && (
        <div className="goods-cart__total">
          <p className="goods-cart__total-price">
            Subtotal: &#36;{fullPrice.toFixed(2)}
          </p>
          <button className="goods-cart__submit-btn" onClick={onCheckout}>
            Checkout
          </button>
        </div>
      )}
      {/* <p className="font-italic mt-4">
        Orders must be placed at least two hours before the earliest delivery window time to have items delivered the same day.
      </p> */}
    </article>
  )
}

Cart.propTypes = {
  products: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      categoryLabel: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
      quantity: PropTypes.number,
      price: PropTypes.number,
    })
  ).isRequired,
  changeQuantity: PropTypes.func.isRequired,
};

const mapStateToProps = ({ cart, category }) => ({
  products: cart.goods,
  categories: category.categories,
})

const mapDispatchToProps = dispatch => ({
  togglePickupCalendar: open => dispatch(togglePickupCalendarState({ open })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
