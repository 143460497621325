import React, { Fragment, useEffect, useState } from 'react'
import { format } from 'date-fns'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import CartSummary from './components/cart-summary'
import OrderSuccessModal from 'components/Modals/OrderSuccessModal/OrderSuccessModal'
import InputGroup from 'components/common/input-group'
import Autocomplete from 'components/common/autocomplete'

import './_Checkout.scss'

import { createOrder, verifyPayment, getOrderQuotation } from 'services/orders'

import { clearCart as clearCartState } from 'reducers/cart/actions'
import { openToast as openToastState } from 'reducers/toast/actions'
import { toggleCart as openCartState } from 'reducers/modals/actions'
import { reformatPhone, reformatZipcode } from 'utils/helper'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      padding: '15px 31px',
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const Checkout = ({
  products: productsState,
  pickupTime,
  user,
  clearCart,
  openToast,
  openCart,
  usStates,
}) => {
  const [products, setProducts] = useState([])
  const [subtotal, setSubtotal] = useState(0)
  const [totalTax, setTotalTax] = useState(0)
  const [shippingFee, setShippingFee] = useState(0)
  const [total, setTotal] = useState(0)
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    billingAddress: '',
    zipCode: user.zipCode,
    phone: user.phone,
    cardNumber: false,
    cardExpiration: false,
    cardCCV: false,
    buildingUnitNo: user.buildingUnitNo,
    note: '',
    code: '',
  })
  const [openOrderSuccess, setOpenOrderSuccess] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [order, setOrder] = useState({})
  // const [openStripeModal, setOpenStripeModal] = useState(false)
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [building, setBuilding] = useState({})
  const [deliveryTime, setDeliveryTime] = useState()
  const [usState, setUsState] = useState()
  const [existingOrder, setExistingOrder] = useState()
  const [promotionAmount, setPromotionAmount] = useState(0)

  const history = useHistory()

  const stripe = useStripe();
  const elements = useElements();

  const requiredFields = {
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    billingAddress: true,
    zipCode: true,
    cardNumber: true,
    cardExpiration: true,
    cardCCV: true,
    building: false,
    buildingUnitNo: false,
    deliveryTime: false,
    city: true,
    state: false,
  }
  const requiredFieldsExistingOrder = {
    ...requiredFields,
    cardNumber: false,
    cardExpiration: false,
    cardCCV: false,
  }

  const setProductList = () => {
    const productList = Object.values(productsState)
    setProducts(productList)
  }

  const onUserLoaded = (user) => {
    setFormData({
      ...formData,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      zipCode: user.zipCode,
      phone: reformatPhone(user?.phone),
      buildingUnitNo: user.buildingUnitNo,
    })
  }
  const onBuildingLoaded = building => {
    setBuilding(building)
    setFormData({
      ...formData,
      building: building?.name,
      billingAddress: building?.address,
    })
  }
  useEffect(() => {
    setProductList()
  }, [productsState])
  useEffect(() => {
    onUserLoaded(user)
    if (typeof user.building === 'object') {
      onBuildingLoaded(user.building)
    }
    if (products.length > 0) {
      getQuotation({
        products: products.map(p => ({ product: p._id, quantity: p.quantity })),
        buildingId: user.building && user.building._id,
      })
    }
  }, [user, products])
  useEffect(() => {
    checkEnablingSubmit(undefined, formData)
  }, [formData])
  const getQuotation = async (data) => {
    const productsData = data.products || products.map(p => ({ product: p._id, quantity: p.quantity }))
    const buildingIdData = data.buildingId || building._id
    const deliveryTimeData = data.deliveryTime || deliveryTime?.value
    const promotionCode = data.promotionCode || formData.promotionCode

    const res = await getOrderQuotation({
      products: productsData,
      building: buildingIdData,
      deliveryTime: deliveryTimeData,
      promotionCode,
    })
    if (res.result !== 'success') {
      if (res.err?.code && res.err?.code === -1) {
        // show cart
        openCart()
      }
      return
    }
    const {
      subtotal,
      totalTax,
      shippingFee,
      total,
      order,
      promotionAmount,
    } = res.data
    setSubtotal(subtotal)
    setTotalTax(totalTax)
    setShippingFee(shippingFee)
    setTotal(total)
    setExistingOrder(order)
    setPromotionAmount(promotionAmount)

    let newFormData
    if (order) {
      newFormData = {
        ...formData,
        firstName: order.firstName,
        lastName: order.lastName,
        email: order.email,
        zipCode: order.zipCode,
        phone: reformatPhone(order?.phone),
        buildingUnitNo: order.buildingUnitNo || user.buildingUnitNo,
        city: order.city,
        state: order.state,
        note: order.note,
        promotionCode: '',
        promotionAmount: 0,
      }
    } else {
      newFormData = {
        ...formData,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email || formData.email,
        zipCode: user.zipCode || formData.zipCode,
        phone: reformatPhone(user?.phone || formData.phone),
        buildingUnitNo: user.buildingUnitNo || formData.buildingUnitNo,
        note: '',
      }
    }
    setFormData({ ...newFormData, deliveryTime: deliveryTimeData })
    return true
  }

  const getItemCount = () => products.reduce((count, product) => count + product.quantity, 0)

  const onFormChange = (field, value) => {
    if (['cardNumber', 'cardExpiration', 'cardCCV'].includes(field)) {
      if (value.complete) {
        formData[field] = true
      } else {
        formData[field] = false
      }
      setFormData({ ...formData })
    } else {
      formData[field] = value
      setFormData({ ...formData })
    }
    // checkEnablingSubmit(existingOrder, formData)
  }

  const checkEnablingSubmit = (orderExisted = existingOrder, formData) => {
    const fields = Object.entries(orderExisted ? requiredFieldsExistingOrder : requiredFields)
    for (let i = 0; i < fields.length; i++) {
      if (fields[i][1] && !formData[fields[i][0]]) return setEnableSubmit(false)
    }
    setEnableSubmit(true)
  }
  const onSelectDeliveryTime = async time => {
    setDeliveryTime(time)
    getQuotation({
      deliveryTime: time.value,
    })
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    setSubmitting(true)
    setSubmitError()
    const payload = {
      ...formData,
      phone: formData.phone.replace(/\D/g, ''),
      building: building._id,
      products: products.map(p => ({
        product: p._id,
        quantity: p.quantity,
      })),
      total: total,
      shippingFee,
      state: usState?.id,
    }
    const res = await createOrder(payload)
    if (res.result !== 'success') {
      setSubmitting(false)
      if (res.err?.code && res.err?.code === -1) {
        // show cart
        openCart()
      }
      return setSubmitError(res.err?.message || res.err)
    }
    onDoneCreatingOrder(res.data)
  }

  const onDoneCreatingOrder = async order => {
    await setOrder(order)
    setEnableSubmit(false)
    setSubmitting(false)
    if (!existingOrder) {
      try {
        await submitStripe(order.clientSecret, order._id)
      } catch (err) {
        setSubmitting(false)
        setEnableSubmit(true)
        return openToast(err.response.data.err)
      }
    } else {
      doneCheckout()
    }
  }

  const submitStripe = async (clientSecret, orderId) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const element = elements.getElement(CardNumberElement)
    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: element,
        billing_details: {
          name: `${formData.firstName} ${formData.lastName}`,
          address: {
            postal_code: formData.zipCode,
            state: usState?.id,
          }
        },
      }
    })
    console.log(`confirmCardSetup ${JSON.stringify(result)}`)
    if (result.error) {
      return setSubmitError(result.error.message)
    }
    setSubmitError('')
    onStripeSubmit(orderId, result.setupIntent)
  }

  const onStripeSubmit = async (orderId, result) => {
    await verifyPayment(orderId, { paymentId: result.payment_method })
    doneCheckout()
  }
  const doneCheckout = () => {
    clearCart()
    setOpenOrderSuccess(true)
  }

  const onCloseSuccessModal = () => {
    setOpenOrderSuccess(false)
    if (user._id) {
      history.push(`/orders/${order._id}`)
    } else {
      history.push(`/`)
    }
  }

  const onApplyPromotion = async () => {
    try {
      const success = await getQuotation({
        promotionCode: formData.promotionCode,
      })
      if (!success) {
        setFormData({
          ...formData,
          promotionCode: '',
        })
        return
      }
    } catch (err) {
      setFormData({
        ...formData,
        code: '',
      })
      return
    }
    return openToast('Code applied successfully', 'success')
  }

  return (
    <Fragment>
      <div style={{ marginLeft: '5%', width: '90%' }} className='checkout'>
        <div className='personal-information-page'>
          <div className='personal-information-form'>
            {/* <PersonalInformationForm onFormChange={onFormChange} /> */}
            <form id='checkout-form' onSubmit={onSubmit}>
              <div className='form-group-left'>
                <div className='form-group'>
                  <h3>Checkout</h3>
                  <p>
                    <i>
                      {existingOrder && deliveryTime?.value ?
                        <>* Items will be added to your existing order for your <b>{format(new Date(deliveryTime.value), 'MMM dd, yyyy')}</b> delivery.&nbsp;
                          Otherwise please choose a different delivery date to start a new order.`</>
                        // : (!deliveryTime && `* Please select delivery time`)
                        : ''
                      }
                    </i>
                  </p>
                  {/* <div className='form-row'>
                    <div className='input-group'>
                      <InputGroup
                        label='Building'
                        placeholder=''
                        value={building?.name}
                        required={requiredFields['building']}
                        disabled
                      />
                    </div>
                    <InputGroup
                      label='Building Unit Number *'
                      placeholder=''
                      value={formData.buildingUnitNo}
                      onChange={e => onFormChange('buildingUnitNo', e.target.value)}
                      required={requiredFields['buildingUnitNo']}
                      tabindex="1"
                      disabled={existingOrder || user.buildingUnitNo}
                    />
                  </div> */}
                  {/* <div className='form-row'>
                    <div className='input-group'>
                      <p>Delivery Date And Time *</p>
                      <Autocomplete
                        options={building?.deliveryDays}
                        labelKey="format"
                        onChange={time => onSelectDeliveryTime(time)}
                        value={deliveryTime}
                        tabindex="2"
                      />
                    </div>
                  </div> */}
                  <div className='form-row'>
                    <InputGroup
                      label='First Name *'
                      placeholder=''
                      value={formData.firstName}
                      onChange={e => onFormChange('firstName', e.target.value)}
                      required={requiredFields['firstName']}
                      tabindex="3"
                      disabled={existingOrder}
                    />
                    <InputGroup
                      label='Last Name *'
                      placeholder=''
                      value={formData.lastName}
                      onChange={e => onFormChange('lastName', e.target.value)}
                      required={requiredFields['lastName']}
                      tabindex="4"
                      disabled={existingOrder}
                    />
                  </div>
                  <div className='form-row'>
                    <InputGroup
                      label='Email *'
                      placeholder=''
                      value={formData.email}
                      type="email"
                      onChange={e => onFormChange('email', e.target.value)}
                      required={requiredFields['email']}
                      tabindex="5"
                      disabled={existingOrder}
                    />
                    <InputGroup
                      label='Phone Number *'
                      placeholder=''
                      type="text"
                      pattern="^\D*(\d{3})\D*(\d{3})\D*(\d{4})"
                      value={formData.phone}
                      onChange={e => onFormChange('phone', reformatPhone(e.target.value))}
                      required={requiredFields['phone']}
                      note="We only call or text if there is an issue with your order, your info will never be given away."
                      tabindex="6"
                      disabled={existingOrder}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <h3>Payment Details</h3>
                  {!existingOrder && (
                    <>
                      <div className="form-row">
                        <div className="input-group">
                          <p>Card Number *</p>
                          <CardNumberElement
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={e => onFormChange('cardNumber', e)}
                            tabindex="7"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="input-group">
                          <p>Expiration Date *</p>
                          <CardExpiryElement
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={e => onFormChange('cardExpiration', e)}
                            tabindex="8"
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="input-group">
                          <p>CVC *</p>
                          <CardCvcElement
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={e => onFormChange('cardCCV', e)}
                            tabindex="9"
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className='form-row'>
                    <InputGroup
                      label='Billing Address *'
                      placeholder=''
                      required={requiredFields['billingAddress']}
                      value={formData.billingAddress}
                      onChange={e => onFormChange('billingAddress', e.target.value)}
                      tabindex="10"
                      disabled={existingOrder}
                    />
                    <InputGroup
                      label='Zip Code *'
                      placeholder=''
                      type="text"
                      value={formData.zipCode}
                      pattern="^\D*(\d{5})$"
                      onChange={e => onFormChange('zipCode', reformatZipcode(e.target.value))}
                      required={requiredFields['zipCode']}
                      tabindex="11"
                      disabled={existingOrder}
                    />
                  </div>
                  <div className='form-row'>
                    <InputGroup
                      label='City *'
                      placeholder=''
                      value={formData.city}
                      onChange={e => onFormChange('city', e.target.value)}
                      required={requiredFields['city']}
                      tabindex="12"
                      disabled={existingOrder}
                    />
                    <div className='input-group'>
                      <p>State</p>
                      <Autocomplete
                        options={usStates}
                        labelKey="name"
                        onChange={state => setUsState(state)}
                        value={usState}
                        tabindex="13"
                        disabled={existingOrder}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-group-right'>
                <div className='form-group'>
                  <div className='form-row'>
                    <InputGroup
                      placeholder='Comments'
                      required={requiredFields['note']}
                      value={formData.note}
                      onChange={e => onFormChange('note', e.target.value)}
                      tabindex="14"
                      disabled={existingOrder}
                    />
                  </div>
                  <div className='checkout-summary'>
                    <CartSummary
                      onSubmit={onSubmit}
                      itemCount={getItemCount()}
                      subtotal={subtotal}
                      tax={totalTax}
                      total={total}
                      shippingFee={shippingFee}
                      showPromotion={!existingOrder}
                      promotionCode={formData.promotionCode}
                      promotionAmount={promotionAmount}
                      onPromotionCodeChange={e => onFormChange('promotionCode', e.target.value)}
                      onApplyPromotion={onApplyPromotion}
                      enableSubmit={enableSubmit}
                      submitting={submitting}
                      existingOrder={existingOrder}
                      submitError={submitError}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <OrderSuccessModal
        open={openOrderSuccess}
        handleClose={onCloseSuccessModal}
        orderId={order.shortId}
        pickupTime={pickupTime.from}
      />
    </Fragment >
  )
}

const mapStateToProps = ({ cart, user, store, buildings, common }, { match }) => ({
  // products: getStoreCart(cart.goods, match.params.id),
  products: cart.goods,
  store: store.selectedStore,
  user,
  pickupTime: cart.pickupTimeStores || {},
  deliveryTime: cart.deliveryTime,
  buildings: buildings.buildings,
  usStates: common.usStates,
  productConstants: common.productConstants,
})

const mapDispatchToProps = dispatch => ({
  clearCart: () => dispatch(clearCartState()),
  openToast: (message, type) => dispatch(openToastState({ message, type })),
  openCart: () => dispatch(openCartState({ open: true })),
})

const CheckoutWrapper = ({ ...props }) => {
  return (
    <Elements stripe={stripePromise}>
      <Checkout
        {...props}
      />
    </Elements>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutWrapper)