import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';

import './_styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
    margin: '30px 0 60px',
  },
}));

export default function PaginationButtons({ count, onChange, page }) {
  const classes = useStyles();

  return (
    <Fragment>
      {count !== 0 && (
        <Pagination
          count={count}
          boundaryCount={1}
          page={Number(page)}
          onChange={(_, page) => onChange(page)}
          className={classes.root}
          renderItem={(item) => (
            <PaginationItem
              {...item}
            />
          )}
        />
      )}
    </Fragment>
  );
}