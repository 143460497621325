import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  profileDropdown: {
    marginTop: '10px',
    '&::before': {
      position: 'absolute',
      top: '-7px',
      left: '9px',
      display: 'inline-block',
      borderRight: '7px solid transparent',
      borderBottom: '7px solid #ccc',
      borderLeft: '7px solid transparent',
      borderBottomColor: 'rgba(0, 0, 0, 0.2)',
      content: '""',
      ':not(:last-child)': {
        borderBottom: '1px solid #000',
      }
    }
  },
  profileMenuItem: {
    width: '180px',
    fontSize: '13px',
    padding: '5px 18px',
  },
  profileMenuItemLastChild: {
    borderBottom: 'none',
  },
  profileMenuItemInner: {
    padding: '11px 0',
    borderBottom: '1px solid #F1F1F3',
    width: '100%',
    textAlign: 'center',
  },
  profileMenuItemInnerLastChild: {
    borderBottom: 'none',
  }
}));