import React from 'react'
import { Menu, MenuItem } from '@material-ui/core';

import useStyles from './style';

export default ({
  menuItems = [],
  anchorEl,
  setAnchorEl,
}) => {
  const classes = useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.profileDropdown
      }}
    >
      {menuItems.map(({ onClick, title }) => (
        <MenuItem onClick={onClick} classes={{ root: classes.profileMenuItem }}>
          <div className={classes.profileMenuItemInner}>
            {title}
          </div>
        </MenuItem>))}
    </Menu>
  )
}
